<template lang="pug">
  .namual-form-wrapper
    .namual-form-wrapper--header
      p.subtitle {{ isImportType ? 'pages.setup_wizard.import_your_users_with_a_csv_file' :
        | 'pages.setup_wizard.create_manually_msg' | translate }}
      p.subtitle {{ 'pages.setup_wizard.attention_import_message' | translate }}
    div(v-if="isImportType")
      input(style="display: none" type="file" accept=".csv" ref="uploadCsv" @change="onFileUpload($event)")
      button.btn.btn-secondary.btn-lg(@click.prevent="openInput")
        i.flaticon-upload
        | {{ this.filename || 'pages.setup_wizard.import_csv' | translate | truncateText(25) }}
    a.active-dark-text.download_example(
      v-if="isImportType"
      :href="dataUrl"
      target="_blank"
      download="data-sample.csv")
      | {{ 'pages.setup_wizard.download_example_csv' | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ManualForm',

  props: {
    isSetupPage: Boolean,
    isImportType: Boolean
  },

  methods: {
    ...mapActions('setupWizard', [
      'setEmployeesCsv'
    ]),

    nextStep () {
      this.$emit('next')
      this.setEmployeesCsv(null)
    },

    onFileUpload (event) {
      this.setEmployeesCsv(event.target.files[0])
      if (!this.isSetupPage) {
        this.$emit('on-file-uploaded')
        this.$refs.uploadCsv.value = ''
      }
    },

    openInput () {
      this.$refs.uploadCsv.click()
    }
  },

  computed: {
    ...mapGetters('setupWizard', [
      'employeesCsv'
    ]),

    filename () {
      if (this.employeesCsv && this.employeesCsv.name) {
        return this.employeesCsv.name
      }
      return ''
    },

    dataUrl () {
      const BASE_URL = process.env.VUE_APP_BASE_URL
      return BASE_URL + '/data-sample.csv'
    }
  }
}
</script>

<style lang="scss" scoped>
.namual-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  &--header{
    text-align: center;
    width: 70%;
  }

  .download_example  {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 0.8rem;
  }
}
</style>
