<template lang="pug">
  .kt-wizard-v1__wrapper
    .kt-form(v-if="loaded")
      div(data-ktwizard-type="step-content" data-ktwizard-state="current")
        h2.title {{ 'pages.setup_wizard.data_and_synchronization' | translate }}
        p.subtitle {{ 'pages.setup_wizard.type_of_syncronize_message' | translate }}
        .kt-heading.kt-heading--md
        .kt-form__section.kt-form__section--first
          .kt-wizard-v1__form
            .form-group.row
              label.col-md-3.col-form-label.title {{ 'pages.setup_wizard.data_source' | translate }}
              .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.cloud' | translate }}
                    input(type="radio" name="sync-provider"
                      :checked="checkStep(syncTypes.CLOUD_PROVIDER)"
                      @click="changeDataSource(syncTypes.CLOUD_PROVIDER)")
                    span
              .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.csv_import' | translate }}
                    input(
                      type="radio"
                      name="sync-provider"
                      :checked="checkStep(syncTypes.CSV_IMPORT)"
                      @click="changeDataSource(syncTypes.CSV_IMPORT)")
                    span
              .col-md-3
                .kt-radio-inline
                  label.kt-radio.subtitle {{ 'pages.setup_wizard.manual' | translate }}
                    input(
                      type="radio"
                      name="sync-provider"
                      :checked="checkStep(syncTypes.MANUAL)"
                      @click="changeDataSource(syncTypes.MANUAL)")
                    span

            sync-provider-form(
              isSetupPage
              :startSync="startSync"
              :secretInfo="secretInfo"
              v-show="checkStep(syncTypes.CLOUD_PROVIDER)"
              @on-load-data="storeSync")
            manual-form(
              isSetupPage
              v-show="checkStep(syncTypes.MANUAL)")
            manual-form(
              isSetupPage
              isImportType
              v-show="checkStep(syncTypes.CSV_IMPORT)")

      .kt-form__actions
        v-btn(
          text
          @click="prevStep")
          | {{ 'ui.buttons.go_back' | translate }}
        v-btn(
          :loading="loadingNextStep"
          :disabled="disabledParser"
          @click="nextStep")
          | {{ 'ui.buttons.next_step' | translate }}
          i.fa.fa-arrow-right

    .data-sync-preloader-wrap(v-else)
      v-preloader

    ui-modal(
      :show="isLoadingSync"
      :isLoadingModal="true")

</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'
import { DATA_SYNC_TYPES } from '@/util/constants'

import UiModal from '@/components/ui/Modal'
import ManualForm from './ManualForm'
import SyncProviderForm from './SyncProviderForm'

export default {
  name: 'DataSyncStepper',

  components: {
    SyncProviderForm,
    ManualForm,
    UiModal
  },

  async mounted () {
    const { data } = await api.restoreDataSyncProvider()
    if (data.type === this.syncTypes.CLOUD_PROVIDER || !data.type) {
      this.currentStep = this.syncTypes.CLOUD_PROVIDER
    } else {
      this.currentStep = this.syncTypes.MANUAL
    }
    this.currentTypeSync = data.type
    this.secretInfo.syncProvider = data.provider || this.secretInfo.syncProvider
    this.secretInfo.providerConnectData.tenant = data.providerConnectData ? data.providerConnectData.tenant : ''
    this.loaded = true
  },

  data: () => ({
    loaded: false,
    currentTypeSync: DATA_SYNC_TYPES.CLOUD_PROVIDER,
    secretInfo: {
      syncProvider: {},
      providerConnectData: { tenant: '' }
    },
    currentStep: DATA_SYNC_TYPES.CLOUD_PROVIDER,
    isLoadingSync: false,
    enableNextStepForSync: false,
    loadingNextStep: false
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    changeDataSource (param) {
      this.currentStep = param
    },

    async nextStep () {
      this.loadingNextStep = true
      await api.storeDataSyncType({ value: this.currentStep })
      this.loadingNextStep = false
      this.$router.push('/setup/super-admin')
    },

    prevStep () {
      this.$router.push('/setup/company')
    },

    async startSync () {
      if (this.isLoadingSync) return
      this.clearErrors()
      this.isLoadingSync = true
      this.enableNextStepForSync = false
      if (this.currentTypeSync !== this.syncTypes.CLOUD_PROVIDER) {
        await api.storeDataSyncType({ value: this.syncTypes.CLOUD_PROVIDER })
      }
      const { error } = await api.startDataSyncProvider()
      this.isLoadingSync = false
      if (!error) {
        this.enableNextStepForSync = true
      }
      return error
    },

    async storeSync () {
      this.clearErrors()
      const storeType = await api.storeDataSyncType({ value: this.syncTypes.CLOUD_PROVIDER })
      if (storeType.error) return
      const body = {}
      body.providerId = this.secretInfo.syncProvider.id
      body.providerConnectData = { tenant: this.secretInfo.providerConnectData.tenant }
      await api.storeDataSyncProvider(body)
    },

    checkStep (step) {
      return this.currentStep === step
    }
  },

  computed: {
    syncTypes () {
      return DATA_SYNC_TYPES
    },

    disabledParser () {
      return this.currentStep === this.syncTypes.CLOUD_PROVIDER &&
        !this.enableNextStepForSync
    }
  }
}
</script>

<style lang="scss" scoped>
  .data-sync-preloader-wrap {
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
